import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import EveryoneLogo from './EveryoneLogo';
import Navigation from '../Menus/navigation';

const HeaderContainer = styled.header`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 25px 50px 26px 50px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1000;

  background-color: ${(props) =>
    props.location === '/artists' || props.location === '/contact'
      ? 'rgb(181,197,246)'
      : '#f6f8fe'};

  transition: 500ms background-color ease;

  @media (max-width: 767px) {
    padding: 25px;
  }

  & .logo {
    z-index: 100;
  }

  @media (max-width: 576px) {
    padding: 25px 20px;
  }
`;

const Header = ({ menuLinks, logo, location }) => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let header = document.querySelector('header');
    header.style.backgroundColor = '';
    header.style.transition = '500ms background-color ease';
    let body = document.querySelector('body');
    body.style.backgroundColor = '';
  });

  return (
    <HeaderContainer location={location}>
      <Link to={'/'} className="logo">
        <EveryoneLogo image={logo.url} />
      </Link>

      <Navigation menuLinks={menuLinks} />
    </HeaderContainer>
  );
};
export default Header;
