import React from 'react';
import styled from 'styled-components';

import MailingList from '../Contact/mailingList';

const FooterContainer = styled.footer`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  font-size: 14px;

  /* background-color: ${(props) =>
    props.location === '/' ? 'rgb(181, 197, 246)' : 'transparent'}; */

  padding: ${(props) => (props.location === '/' ? '40px 50px' : '40px 0')};

  @media (max-width: 767px) {
    padding: ${(props) => (props.location === '/' ? '40px 25px' : '40px 0')};
  }

  @media (max-width: 576px) {
    padding: ${(props) => (props.location === '/' ? '40px 20px' : '40px 0')};
  }
`;

const Links = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & a {
    padding-right: 20px;
  }

  & a:hover {
    color: #fa3c44;
  }

  & p {
    margin: 0;
    margin-left: auto;
  }

  @media (max-width: 767px) {
    & a:last-of-type {
      order: 1;
      width: 100%;
    }

    & a:first-of-type {
      order: 2;
      width: 100%;
    }

    & div {
      order: 3;
      width: 100%;
    }

    & p {
      order: 4;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .email-address {
      display: none;
    }
  }

  @media (max-width: 500px) {
    & a:first-of-type {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const Footer = ({ instagram, contact, mailingListText, location }) => {
  const formattedEmail = contact.url.replace('mailto:', '');
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterContainer location={location}>
      <Links>
        <a href={contact.url} target={contact.target} className="email-address">
          {formattedEmail}
        </a>
        <a href={instagram.url} target={instagram.target}>
          Instagram
        </a>
        <MailingList text={mailingListText} />
        <p>©Everyone Agency Ltd, {year}</p>
      </Links>
    </FooterContainer>
  );
};

export default Footer;
