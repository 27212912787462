import React from 'react';
import styled from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactPlayer from 'react-player';

const SplashContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10000;

  background-color: rgba(69, 109, 232, 0.4);

  &:hover .arrow {
    opacity: 1;
  }
`;

const SplashLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  max-width: 970px;
  width: 100%;
  padding: 0 40px;
`;

const Video = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  z-index: 1;

  & iframe {
    width: 100vw !important;
    height: 56.25vw !important;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const InnerSplashContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  cursor: url(/images/close@1x.png) 0 0, pointer;
  cursor: -webkit-image-set(
        url(/images/close@1x.png) 1x,
        url(/images/close@2x.png) 2x
      )
      0 0,
    pointer;
`;

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoLoaded: false,
    };
  }

  targetElement = null;

  componentDidMount() {
    disableBodyScroll();
  }

  componentDidUpdate(prevProps) {
    if (this.props.splashActive !== prevProps.splashActive) {
      clearAllBodyScrollLocks();

      setTimeout(() => {
        sessionStorage.setItem('splashActive', true);
      }, 1200);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render(props) {
    return (
      <SplashContainer splashActive={this.props.splashActive}>
        <InnerSplashContainer onClick={() => this.props.onClick()}>
          <SplashLogo src="/images/splash-everyone-logo.svg" />
        </InnerSplashContainer>

        {/* {this.props.video_embed ? (
          <Video>
            <ReactPlayer
              url={this.props.video_embed}
              playing
              muted
              loop
              playsinline
              config={{
                vimeo: {
                  background: 1,
                  loop: 1,
                  fullscreen: 0,
                },
              }}
              onStart={() =>
                this.setState({
                  isVideoLoaded: true,
                })
              }
            />
          </Video>
        ) : (
          <img src={this.props.splashImage.fluid.srcWebp} />
        )} */}
      </SplashContainer>
    );
  }
}

export default SplashScreen;
