import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MobileMenuIcon from '../Icons/mobile-menu-icon';

const Navigation = styled.nav`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 25px 32px 25px;
  background-color: #fff;

  font-size: 20px;
  line-height: 40px;

  ul {
    padding: 0;
    margin: 0 0 0 50vw;

    @media (max-width: 400px) {
      margin: 0 0 0 160px;
    }
  }

  li {
    list-style: none;
    display: inline-block;
    width: 100%;
  }
  a {
    text-decoration: none;
    text-transform: capitalize;

    color: rgb(1, 59, 232);
    &:active,
    &:hover {
      color: rgb(250, 60, 68);
    }
  }
`;

const Toggle = styled.button`
  cursor: pointer;
  z-index: 10;

  width: 20px;
  height: 20px;
`;

const MobileMenu = ({ menuLinks }) => {
  const [menu, toggleMenu] = useState(false);

  const links = menuLinks.map((link) => {
    if (link.type === `internal`) {
      return (
        <li key={link.name}>
          <Link
            to={link.link}
            activeStyle={{ color: '#fa3c44' }}
            partiallyActive={true}
            onClick={() => toggleMenu(false)}
          >
            {link.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={link.name}>
          <a
            href={link.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => toggleMenu(false)}
          >
            {link.name}
          </a>
        </li>
      );
    }
  });

  return (
    <React.Fragment>
      <Toggle onClick={() => toggleMenu(!menu)}>
        <MobileMenuIcon isOpen={menu} />
      </Toggle>
      <Navigation isOpen={menu}>
        <ul>
          <li key="home">
            <Link
              to={'/'}
              activeStyle={{ color: '#fa3c44' }}
              onClick={() => toggleMenu(false)}
            >
              Home
            </Link>
          </li>

          {links}
        </ul>
      </Navigation>
    </React.Fragment>
  );
};

export default MobileMenu;
