import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Navigation = styled.nav`
  display: block;
  position: relative;
  z-index: 1;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding-right: 22px;

    display: inline-block;
    top: 60px;
    position: sticky;

    &:last-of-type {
      padding-right: 0;
    }
  }
  a {
    text-decoration: none;
    text-transform: capitalize;

    color: rgb(1, 59, 232);
    &:active,
    &:hover {
      color: rgb(250, 60, 68);
    }
  }
`;

const DesktopMenu = ({ menuLinks }) => {
  const links = menuLinks.map((link) => {
    if (link.type === `internal`) {
      return (
        <li key={link.name}>
          <Link
            to={link.link}
            activeStyle={{ color: '#fa3c44' }}
            partiallyActive={true}
          >
            {link.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={link.name}>
          <a href={link.link} target="_blank" rel="noreferrer">
            {link.name}
          </a>
        </li>
      );
    }
  });

  return (
    <Navigation>
      <ul>
        <li key="home">
          <Link to={'/'} activeStyle={{ color: '#fa3c44' }}>
            Home
          </Link>
        </li>

        {links}
      </ul>
    </Navigation>
  );
};

export default DesktopMenu;
