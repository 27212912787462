// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-js": () => import("./../../../src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/mailing-list.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-animation-js": () => import("./../../../src/templates/animation.js" /* webpackChunkName: "component---src-templates-animation-js" */),
  "component---src-templates-artist-animation-js": () => import("./../../../src/templates/artist-animation.js" /* webpackChunkName: "component---src-templates-artist-animation-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-client-page-js": () => import("./../../../src/templates/client-page.js" /* webpackChunkName: "component---src-templates-client-page-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

