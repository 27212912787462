import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'BatonTurbo';
    src: url('/fonts/BatonTurboWeb-Regular.eot');
    src: url('/fonts/BatonTurboWeb-Regular.woff') format('woff');
    font-display: block;
  }

  @font-face {
    font-family: 'EVERYONERegular';
    src: url('/fonts/EVERYONE-Regular.woff') format('woff');
    font-display: block;
  }

  body {
    font-family: 'BatonTurbo', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-size: 20px;
    line-height: 26px;

    font-weight: 400;
    letter-spacing: 0.4px;
  }

  h1, h2, h3{
    font-family: 'EVERYONERegular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    text-transform: uppercase;
    text-align: center;
  }

  body {
    background-color: #f6f8fe;
    color: rgba(1, 59, 210, 1);

    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -webkit-tap-highlight-color: transparent;

    -webkit-overflow-scrolling: touch;

    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
    }

        @media (max-width: 500px) {
        font-size: 16px;
        line-height: 22px;
    }
  }



  .body-container,
  .close-artist-bio{
    transition: 500ms background-color ease;
  }


  video{
    max-width: 100%;
    width: 100%;
  }


    & a,
    & a:visited {
      text-decoration: none;
      color: inherit;
    }

    & a:focus{
      outline: none;
    }

    & a.active{
      color: #fa3c44;
    }


    h1, h2, h3, h4, h5, h6{
      margin: 0;
      font-weight: normal;
    }

    h1 {
      font-size: 70px;
      line-height: 87px;

      @media (max-width: 950px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    h2{
      font-size: 49px;
      line-height: 63px;

      @media (max-width: 950px) {
          font-size: 26px;
          line-height: 32px;
      }

    }

    h3{
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0;

      @media (max-width: 950px) {
          font-size: 22px;
          line-height: 28px;
      }
    }


    button {
      cursor: pointer;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      border: 0;
      background-color: transparent;

      color: rgba(1, 59, 210, 1);

      &:hover {
        color: #fa3c44;
      }

      &:focus {
        outline: 0;
      }
    }


img {
  width: 100%;
  display: block;
}


.gatsby-image-wrapper{
  height: 100%;
}


.close-artist-bio{
  cursor: url(/images/close@1x.png) 0 0, pointer;
  cursor: -webkit-image-set(url(/images/close@1x.png) 1x, url(/images/close@2x.png) 2x) 0 0, pointer;
}





.lazyload-placeholder{
  background-color: rgba(69, 109, 232, 0.4);
}




// .muuri {
//   width: 100%;

//   // margin-left: -15px;
//   // margin-right: -15px;
// }


// .item {
//   cursor: pointer;
//   position: absolute;
//   // margin: 0 15px 30px 15px;

//     margin-bottom: 30px;
//     padding: 0 15px;
//     box-sizing: content-box;

// }

// .item.w1{
//   width: 100%;

//   max-width: 25%;

//   @media(max-width: 767px){
//     max-width: 50%;
//   }

// }

// .item.w2{
//   width: 100%;

//   max-width: 50%;

//   @media(max-width: 767px){
//     max-width: 100%;
//   }
// }
`;

export default GlobalStyle;
