import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);

// exports.onRouteUpdate = () => {
//   window.scrollTo(0, 0);
// };
