import React from 'react';
import styled from 'styled-components';

const Logo = styled.div`
  width: 170px;
  height: auto;
  // display: flex;
  // flex-direction: row;
  z-index: 100;

  @media (max-width: 767px) {
    display: block;
  }
  @media (max-width: 500px) {
    width: 130px;
  }
`;

const EveryoneLogo = props => (
  <Logo>
    <img src={props.image} alt="Everyone Agency Logo" />
  </Logo>
);

export default EveryoneLogo;
