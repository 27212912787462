import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SplashScreen from '../Homepage/splashScreen';
import { Row, Col } from 'styled-bootstrap-grid';

import Header from './header';
import Footer from './footer';

const SplashContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10000;

  transform: translateY(${(props) => (props.splashActive ? 0 : '-100%')});
  transition: 1s transform ease;

  cursor: pointer;

  &:hover .arrow {
    opacity: 1;
  }
`;

const Main = styled.main`
  flex: 1;
  flex-direction: column;
  margin-top: 80px;
  min-height: calc(100vh - 80px);

  @media (max-width: 767px) {
    margin-top: 73px;
  }
`;

const App = ({ location, data, children }) => {
  const [splashActive, setSplashActive] = useState(true);
  const [hasSplashRanOnce, setHasSplashRanOnce] = useState(false);

  useEffect(() => {
    let header = document.body.querySelector('header');

    if (header !== null) {
      header.style.paddingBottom = '26px';
    }

    // if (location.pathname === '/') {
    //   let findIfSplashRun = setHasSplashRanOnce(
    //     sessionStorage.getItem('splashActive')
    //   );

    //   console.log(findIfSplashRun);

    //   if (findIfSplashRun !== null) {
    //     setSplashActive(false);
    //     setHasSplashRanOnce(findIfSplashRun);
    //   }
    // } else {
    //   sessionStorage.setItem('splashActive', true);
    //   setSplashActive(false);
    //   setHasSplashRanOnce(true);
    // }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/') {
      let findIfSplashRun = setHasSplashRanOnce(
        sessionStorage.getItem('splashActive')
      );

      if (findIfSplashRun !== undefined) {
        setSplashActive(false);
        setHasSplashRanOnce(findIfSplashRun);
      } else {
        sessionStorage.setItem('splashActive', true);
        setSplashActive(true);
        setHasSplashRanOnce(false);
      }
    } else {
      sessionStorage.setItem('splashActive', true);
      setSplashActive(false);
      setHasSplashRanOnce(true);
    }
  }, []);

  const slideUpSplashScreen = () => {
    setSplashActive(false);

    setTimeout(() => {
      setHasSplashRanOnce(true);
    }, 1200);
  };

  return (
    <>
      {hasSplashRanOnce === false && location.pathname === '/' && (
        <SplashContainer
          splashActive={splashActive}
          onClick={() => slideUpSplashScreen()}
        >
          <SplashScreen
            splashImage={data.prismicHomepage.data.image}
            image={data.prismicHomepage.data.logo.url}
            video_thumbnail={data.prismicHomepage.data.video_embed_thumbnail}
            video_embed={data.prismicHomepage.data.video_embed.embed_url}
            onClick={() => slideUpSplashScreen()}
            splashActive={splashActive}
          />
        </SplashContainer>
      )}

      {(splashActive === false || location.pathname !== '/') && (
        <>
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            logo={data.prismicHomepage.data.logo}
            location={location.pathname}
          />

          <Main>{children}</Main>

          {location.pathname !== '/contact' && (
            <Footer
              location={location.pathname}
              instagram={data.prismicHomepage.data.instagram}
              contact={data.prismicHomepage.data.contact}
              mailingListText={data.prismicAbout.data.mailing_list_text}
            />
          )}
        </>
      )}
    </>
  );
};

export default App;
