import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  transition: 150ms transform linear;
  transform: rotateZ(${(props) => (props.isOpen ? '-45deg' : '0deg')});
  transform-origin: center;

  width: ${(props) => (props.width ? props.width : '18px')};
  height: ${(props) => (props.height ? props.height : '18px')};
`;

const MobileMenuIcon = ({ isOpen, width, height }) => (
  <Icon viewBox="0 0 20 20" isOpen={isOpen} width={width} height={height}>
    <polygon
      className="st0"
      fill="#fa3c44"
      points="7.6,20 12.6,20 12.6,20 12.7,12.4 20,12.4 20,7.3 20,7.3 12.7,7.3 12.7,0 7.6,0 7.6,7.3 0,7.3 0,7.3
	0,12.4 7.6,12.4 7.6,20 "
    />
  </Icon>
);

export default MobileMenuIcon;
