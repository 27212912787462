import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import '../Styles/modal.css';
import { MailchimpForm } from './mailchimpForm';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

const MailingListButton = styled.button`
  cursor: pointer;

  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  border: 0;
  background-color: transparent;
  color: #013be8;

  &:hover {
    color: #fa3c44;
  }

  &:focus {
    outline: 0;
  }
`;

const MailingListButtonContainer = styled.div``;

class MailingList extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render(props) {
    return (
      <React.Fragment>
        <MailingListButtonContainer>
          <MailingListButton onClick={this.openModal}>
            {this.props.text}
          </MailingListButton>
        </MailingListButtonContainer>

        <Modal
          isOpen={this.state.modalIsOpen}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.closeModal}
          contentLabel="Mailchimp Signup"
          className="Modal"
          overlayClassName="Overlay"
        >
          <MailchimpForm closeModal={() => this.closeModal()} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default MailingList;
