import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAsyncFn } from 'react-use';

const ContactForm = styled.div`
  height: auto;

  & p:first-of-type {
    margin-top: 45px;
  }

  & p {
    margin: 0 0 30px 0;
    padding: 0 0 3px 0;
    width: 100%;

    border-bottom: 2px solid #013be8;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }

  & input,
  textarea {
    font-size: 17px;
    color: #013be8;

    margin-left: 0;
    width: 100%;
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;
  }

  @media only screen and (max-width: 767px) {
    & input,
    textarea {
      font-size: 15px;
      line-height: 21px;
    }
  }
`;

const ContactHeader = styled.h2`
  color: #013be8;
  text-align: left;
  font-size: 22px;
  font-weight: 400;

  margin: 0;

  @media only screen and (max-width: 767px) {
    font-size: 15px;
  }
`;

const MailchimpFormContainer = styled(ContactForm)`
  & input {
    ::-webkit-input-placeholder {
      color: #013be8;
    }
    ::-moz-placeholder {
      color: #013be8;
    }
    :-ms-input-placeholder {
      color: #013be8;
    }
    :-moz-placeholder {
      color: #013be8;
    }
    ::placeholder {
      color: #013be8;
    }
  }
`;

const MailchimpText = styled.div`
  font-size: 12px;
  line-height: 16px;

  padding-top: 100px;

  & p {
    margin: 0;
  }

  & a:hover {
    color: #fa3c44;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 45px;
  }
`;

const CloseModal = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  font-size: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0;
  margin: 0;

  width: 45px;
  height: 45px;

  border: 0;
  -webkit-appearance: none;

  background-color: transparent;
  color: #013be8;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

const SignUpButton = styled.button`
  font-size: 17px;
  text-align: right;
  padding: 0;
  margin: 0;

  width: 100px;

  border: 0;
  -webkit-appearance: none;

  background-color: transparent;
  color: #013be8;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fa3c44;
  }

  @media only screen and (max-width: 767px) {
    font-size: 15px;
    line-height: 21px;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 3px;
  border-bottom: 2px solid #013be8 !important;
`;

const ThankYouMessage = styled.div`
  font-size: 17px;
  margin-top: 1em;
  color: #fa3c44;
`;

export const MailchimpForm = ({ closeModal }) => {
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [message, setMessage] = useState(null);

  const [mailerliteSubscribeEmail, getMailerliteSubscribeEmail] = useAsyncFn(
    async (email, firstName, lastName) => {
      const response = await fetch(
        `/.netlify/functions/mailerlite-subscribe-email`,
        {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
          }),
          headers: new Headers({
            Accept: 'application/json',
            'X-MailerLite-ApiDocs': 'true',
            'Content-Type': 'application/json',
          }),
        }
      );

      const result = await response.json();
      return result;
    },
    []
  );

  useEffect(() => {
    if (mailerliteSubscribeEmail.loading === false) {
      if (mailerliteSubscribeEmail.value !== undefined) {
        if (mailerliteSubscribeEmail.value === `success`) {
          setMessage(
            'Thank you for signing up to the Everyone Agency mailing list.'
          );
          document.getElementById('signup-form').reset();
        }
      }
    }
  }, [mailerliteSubscribeEmail]);

  useEffect(() => {
    if (message) {
      const resetMailingList = setTimeout(() => {
        setMessage(null);
      }, 4000);
      return () => clearTimeout(resetMailingList);
    }
  }, [message]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <MailchimpFormContainer>
      <CloseModal onClick={() => closeModal()}>×</CloseModal>

      <ContactHeader>Newsletter Sign Up</ContactHeader>
      <form id="signup-form" onSubmit={(e) => handleOnSubmit(e)}>
        <p>
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            name="FNAME"
          />
        </p>

        <p>
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            name="LNAME"
          />
        </p>

        <SignUpContainer>
          <input
            maxLength="200"
            type="email"
            id="email"
            name="email"
            autoComplete="email"
            placeholder="Email"
            data-required="true"
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          <SignUpButton
            type="submit"
            onClick={() =>
              getMailerliteSubscribeEmail(email, firstName, lastName)
            }
            disabled={firstName === null && lastName === null && email === null}
          >
            Sign up
          </SignUpButton>
        </SignUpContainer>
      </form>

      <ThankYouMessage>{message}</ThankYouMessage>
    </MailchimpFormContainer>
  );
};
