import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Normalize } from 'styled-normalize';
import { Location } from '@reach/router';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import GlobalStyles from '../Styles/globalStyles';
import 'array-flat-polyfill';
import 'intersection-observer';

import App from './App';

const gridTheme = {
  breakpoints: {
    // defaults below
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 15, // default 15
  },
  col: {
    padding: 15, // default 15
  },
  container: {
    padding: 50, // default 15
    maxWidth: {
      // defaults below
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const Container = styled.div`
  width: 100%;

  padding: ${(props) =>
    props.location === '/' || props.location === '/animation/'
      ? '0'
      : '0 50px'};

  margin-right: auto;
  margin-left: auto;

  background-color: ${(props) =>
    props.location === '/artists' || props.location === '/contact'
      ? 'rgb(181,197,246)'
      : '#f6f8fe'};

  @media (max-width: 767px) {
    padding: ${(props) =>
      props.location === '/' || props.location === '/animation/'
        ? '0'
        : '0 25px'};
  }

  @media (max-width: 576px) {
    padding: ${(props) =>
      props.location === '/' || props.location === '/animation/'
        ? '0'
        : '0 20px'};

    & div[data-name*='row'] {
      margin-right: -10px;
      margin-left: -10px;
    }

    & div[data-name*='col-'] {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            type
          }
        }
      }
      prismicHomepage {
        data {
          seo_text {
            text
          }
          video_embed {
            embed_url
          }
          video_embed_thumbnail {
            fluid(maxWidth: 1200) {
              srcSetWebp
              srcWebp
            }
          }
          image {
            fluid(maxWidth: 1200) {
              srcSetWebp
              srcWebp
            }
          }
          logo {
            url
          }
          contact {
            url
            target
          }
          instagram {
            url
            target
          }
        }
      }
      prismicAbout {
        data {
          mailing_list_text
        }
      }
      allPrismicArtist(sort: { order: ASC, fields: uid }) {
        edges {
          node {
            data {
              name {
                text
              }
            }
          }
        }
      }
    }
  `);

  const artistListText = data.allPrismicArtist.edges
    .map((artist) => artist.node.data.name.text)
    .join(', ');

  return (
    <GridThemeProvider gridTheme={gridTheme}>
      <Location>
        {({ location }) => {
          return (
            <Container location={location.pathname} className="body-container">
              <Normalize />
              <BaseCSS />
              <GlobalStyles />
              <Helmet
                title={'Everyone Agency'}
                meta={[
                  {
                    name: 'description',
                    content: `${data.prismicHomepage.data.seo_text.text}`,
                  },
                  {
                    name: 'keywords',
                    content: `${artistListText}, London, Illustration, Animation, Artist, Everyone, Agency`,
                  },
                  {
                    name: 'og:url',
                    content: `${location.href}`,
                  },
                ]}
                link={[
                  {
                    rel: 'preconnect',
                    href: 'https://images.prismic.io/everyone-agency-ltd',
                  },
                  {
                    rel: 'dns-prefetch',
                    href: 'https://images.prismic.io/everyone-agency-ltd',
                  },
                  {
                    rel: 'preload',
                    href: '/fonts/EVERYONE-Regular.woff',
                    as: 'font',
                    crossOrigin: 'anonymous',
                  },
                  {
                    rel: 'preload',
                    href: '/fonts/BatonTurboWeb-Regular.woff',
                    as: 'font',
                    crossOrigin: 'anonymous',
                  },
                  {
                    rel: 'preload',
                    href: '/fonts/BatonTurboWeb-Regular.eot',
                    as: 'font',
                    crossOrigin: 'anonymous',
                  },
                  {
                    rel: 'dns-prefetch',
                    href: 'https://player.vimeo.com',
                  },
                  {
                    rel: 'preconnect',
                    href: 'https://player.vimeo.com',
                  },
                  {
                    rel: 'dns-prefetch',
                    href: 'https://i.vimeocdn.com',
                  },
                  {
                    rel: 'preconnect',
                    href: 'https://i.vimeocdn.com',
                  },
                  {
                    rel: 'dns-prefetch',
                    href: 'https://f.vimeocdn.com',
                  },
                  {
                    rel: 'preconnect',
                    href: 'https://f.vimeocdn.com',
                  },
                ]}
              />

              <App location={location} data={data} children={children} />
            </Container>
          );
        }}
      </Location>
    </GridThemeProvider>
  );
};

export default Layout;
