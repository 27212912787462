import React from 'react';
import Media from 'react-media';
import DesktopMenu from '../Menus/desktop-menu';
import MobileMenu from '../Menus/mobile-menu';

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      windowWidth: 530,
    };
  }

  componentDidMount() {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidth = window.innerWidth;
      this.setState({
        windowWidth: windowWidth,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Media
          queries={{ medium: '(max-width: 530px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 530 }}
          render={() => <MobileMenu menuLinks={this.props.menuLinks} />}
        />

        <Media
          queries={{ medium: '(min-width: 531px)' }}
          defaultMatches={{ medium: this.state.windowWidth === 531 }}
          render={() => <DesktopMenu menuLinks={this.props.menuLinks} />}
        />
      </React.Fragment>
    );
  }
}

export default Navigation;
